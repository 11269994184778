import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";

import GhostHead from "components/ghost/GhostHead";
import { Layout } from "../components";

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 * @param root0
 * @param root0.data
 * @param root0.location
 */

const Article = styled.article`
    padding: max(12vmin, 64px) 0 max(6.4vmin, 40px);
`;

const ArticleHeader = styled.header`
    padding-bottom: max(3.2vmin, 28px);
    display: grid;
    grid-template-columns:
        [full-start] minmax(max(4vmin, 20px), auto) [wide-start] minmax(
            auto,
            240px
        )
        [main-start] min(720px, calc(100% - max(8vmin, 40px)))
        [main-end] minmax(auto, 240px)
        [wide-end] minmax(max(4vmin, 20px), auto) [full-end];
    > * {
        grid-column: main-start/main-end;
    }
`;

const ArticleTitle = styled.h1`
    margin-bottom: 0;
    font-size: clamp(3.2rem, 5vw, 5.2rem);
    font-weight: 800;
    line-height: 1;
    color: var(--color-darkgrey);
`;

const Container = styled.section`
    display: grid;
    grid-template-columns:
        [full-start] minmax(max(4vmin, 20px), auto) [wide-start] minmax(
            auto,
            240px
        )
        [main-start] min(720px, calc(100% - max(8vmin, 40px)))
        [main-end] minmax(auto, 240px)
        [wide-end] minmax(max(4vmin, 20px), auto) [full-end];

    > * {
        grid-column: main-start/main-end;
    }

    > p {
        font-family: var(--font-serif);
        font-weight: 400;
        font-size: 2rem;
        line-height: 1.6em;

        @media (max-width: 650px) {
            font-size: 1.8rem;
        }
    }

    a {
        color: var(--ghost-accent-color);
        text-decoration: underline;
        word-break: break-word;
    }
    .kg-gallery-card,
    .kg-image-card {
        @media (max-width: 600px) {
            --gap: 0.6rem;
        }
    }
    .kg-image-card img {
        margin: auto;
    }
    .kg-card + :not(.kg-card),
    &:not(.kg-card):not([id]) + .kg-card {
        margin-top: 6vmin;
        margin-bottom: 0;
    }
`;

/* eslint-disable react/prop-types */
export const Head = ({ data, location }) => {
    const metaProps = { data, location, type: "website" };
    const page = data.ghostPage;
    return (
        <>
            <style type="text/css">{`${page.codeinjection_styles}`}</style>
            <GhostHead metaProps={metaProps} />
        </>
    );
};
/* eslint-enable react/prop-types */

const Page = ({ data }) => {
    const page = data.ghostPage;

    return (
        <Layout>
            <main className="site-main">
                <Article>
                    <ArticleHeader>
                        <ArticleTitle>{page.title}</ArticleTitle>
                    </ArticleHeader>
                    <Container
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </Article>
            </main>
        </Layout>
    );
};

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default Page;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            feature_image_caption
            ...GhostPageFields
        }
    }
`;
