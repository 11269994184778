import React from "react";
import PropTypes from "prop-types";
import { useCodeInjection, useGhostSettings } from "app/settings";
import { MetaData } from "components/meta";

const GhostHead = ({ bodyClass, metaProps }) => {
    const site = useGhostSettings();
    useCodeInjection(site);
    return (
        <>
            <MetaData {...metaProps} />
            <html lang={site.lang} />
            <style type="text/css">{`${site.codeinjection_styles}`}</style>
            <body className={bodyClass} />
        </>
    );
};

GhostHead.propTypes = {
    metaProps: PropTypes.object,
    bodyClass: PropTypes.string,
};

export default GhostHead;
